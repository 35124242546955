<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row class="mb-n3">
          <v-col cols="12" md="10" class="mt-5">
            <PageHeader :title="title" />
          </v-col>
          <v-col
            cols="12"
            md="2"
            align="end"
            class="mt-9 pt-0"
            v-if="canConfig"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigTablasProveedores()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a la configuración de tablas de proveedores</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-2 p-0" max-width="1200" outlined>
          <v-container class="py-0">
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-1 px-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      :disabled="filtersApplied.length == 0"
                      @click.stop="closeOpenFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row dense>
                  <!-- Código -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-text-field
                      v-model.trim="codigo"
                      label="Código"
                      hide-details="auto"
                      dense
                      clearable
                      :rules="
                        (cuit == null || cuit == '') &&
                        (nombre == null || nombre == '') &&
                        (nombreFantasia == null || nombreFantasia == '')
                          ? rules.required.concat([
                              rules.requiredTrim(codigo),
                              rules.maxLength(codigo, 10)
                            ])
                          : []
                      "
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Número de CUIL/CUIT -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      dense
                      label="CUIT"
                      hide-details="auto"
                      v-model="cuit"
                      v-mask="['##-#######-#', '##-########-#']"
                      autocomplete="off"
                      :rules="
                        (codigo == null || codigo == '') &&
                        (nombre == null || nombre == '') &&
                        (nombreFantasia == null || nombreFantasia == '')
                          ? rules.required.concat([rules.cuilValido(cuit)])
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Nombre -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-text-field
                      v-model.trim="nombre"
                      label="Nombre/s"
                      hide-details="auto"
                      dense
                      :rules="
                        (codigo == null || codigo == '') &&
                        (cuit == null || cuit == '') &&
                        (nombreFantasia == null || nombreFantasia == '')
                          ? rules.required.concat([
                              rules.requiredTrim(nombre),
                              rules.maxLength(nombre, 50)
                            ])
                          : []
                      "
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Nombre de fantasía -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-text-field
                      v-model.trim="nombreFantasia"
                      label="Nombre fantasía"
                      hide-details="auto"
                      dense
                      :rules="
                        (codigo == null || codigo == '') &&
                        (cuit == null || cuit == '') &&
                        (nombre == null || nombre == '')
                          ? rules.required.concat([
                              rules.requiredTrim(nombreFantasia),
                              rules.maxLength(nombreFantasia, 50)
                            ])
                          : []
                      "
                      clearable
                      outlined
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Incluir deshabilitados -->
                  <v-col cols="4" sm="6" md="4" class="py-0 px-0">
                    <v-subheader class="py-0 px-0">
                      <v-switch
                        class="ml-1"
                        v-model="incluirDeshabilitados"
                        dense
                        outlined
                      ></v-switch>
                      Incluir proveedores deshabilitados
                    </v-subheader>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col class="py-0 px-0" cols="12" md="4" align="center">
                    <v-subheader
                      >(*) Ingresar al menos un parámetro de
                      búsqueda</v-subheader
                    >
                  </v-col>
                  <v-col cols="12" md="6" class="text-center pt-2">
                    <v-btn
                      color="primary"
                      outlined
                      small
                      @click="exportAllProvs()"
                      :loading="exportAllBtnLoading"
                    >
                      Exportar todos los proveedores
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="2" class="text-right pt-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>

                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :loading="applyBtnLoading"
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            item-key="provId"
            :headers="headers"
            :items="proveedores"
            ref="proveedores-data-table"
            :loading="loadingTable"
            class="elevation-1"
            :search="search"
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      class="to-right"
                      @click="openEditModal(null, 'edit')"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.habilitado`]="{ item }">
              <v-icon
                v-if="item.habilitado"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
              <v-icon v-else small color="red" class="d-flex justify-center">
                {{ crossIcon }}
              </v-icon>
            </template>
            <!-- Expansion de usuario -->
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios de carga y modificación</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fecAlta }}
                <strong style="padding-left: 80px">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left: 80px">Fecha modificación:</strong>
                {{ item.fecModi }}
                <strong style="padding-left: 80px"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canChangeState">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openChangeStateModal(item)"
                  >
                    {{
                      !item.habilitado
                        ? "mdi-checkbox-marked-circle-outline"
                        : "mdi-checkbox-marked-circle-minus-outline"
                    }}
                  </v-icon>
                </template>
                <span v-if="item.habilitado">Deshabilitar proveedor</span>
                <span v-else>Habilitar proveedor</span>
              </v-tooltip>
              <v-tooltip left v-if="canSee">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openEditModal(item, 'see')"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver proveedor</span>
              </v-tooltip>
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openEditModal(item, 'edit')"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar proveedor</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openDelete(item.provId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar proveedor</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-col cols="12" class="px-0">
          <v-btn
            color="primary"
            class="to-right"
            outlined
            :disabled="proveedores.length == 0"
            @click="exportarConsulta()"
          >
            Exportar detalle
          </v-btn>
        </v-col>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalEditProveedor"
      v-model="modalEditProveedor"
      @keydown.esc="closeAndReload(null)"
      max-width="80%"
      persistent
    >
      <EditProveedor
        :proveedor="proveedorToEdit"
        :editOrSee="editOrSee"
        @closeAndReload="closeAndReload($event)"
      ></EditProveedor>
    </v-dialog>
    <ConfirmDialog
      :text="
        proveedorToEdit != null && !proveedorToEdit.habilitado
          ? '¿Habilitar proveedor?'
          : '¿Deshabilitar proveedor?'
      "
      :openConfirm.sync="changeStateModal"
      @update:openConfirm="changeStateModal = false"
      @onConfirm="confirmChangeStateProveedor()"/>
    <DeleteDialog
      :titleProp="titleDelete"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="confirmDelete()"
  /></v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import validateCUIL from "@/utils/helpers/validateCUIL";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditProveedor from "@/components/modules/proveedores/gestionProveedores/EditProveedor.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "GestionProveedores",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    EditProveedor,
    DeleteDialog,
    ConfirmDialog
  },
  async mounted() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  data() {
    return {
      title: "Gestión de proveedores",
      rules: rules,
      filtersApplied: [],
      showExpand: false,
      showHelp: false,
      optionCode: enums.webSiteOptions.GESTION_PROVEEDORES,
      settingsIcon: enums.icons.SETTINGS,
      showIcon: true,
      showFilters: true,
      isFormValid: false,
      applyBtnLoading: false,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      cuit: null,
      codigo: null,
      incluirDeshabilitados: false,
      nombre: null,
      nombreFantasia: null,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "provCod"
        },
        {
          text: "CUIT",
          sortable: false,
          value: "cuit"
        },
        {
          text: "Nombre",
          sortable: true,
          value: "provNom"
        },
        {
          text: "Habilitado",
          sortable: false,
          align: "center",
          value: "habilitado"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end"
        },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "end"
        }
      ],
      proveedores: [],
      loadingTable: false,
      search: "",
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      crossIcon: enums.icons.CLOSE_OUTLINE,
      expanded: [],
      allowedActions: null,
      canCreate: false,
      canSee: false,
      canEdit: false,
      canDelete: false,
      canConfig: false,
      canChangeState: false,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      seeIcon: enums.icons.SEE,
      exportAllBtnLoading: false,
      idToDelete: null,
      loadingDeleteBtn: false,
      titleDelete: "¿Eliminar proveedor?",
      openDeleteDialog: false,
      modalEditProveedor: false,
      proveedorToEdit: null,
      editOrSee: null,
      changeStateModal: false
    };
  },
  methods: {
    ...mapActions({
      getProveedoresByFilters: "proveedores/getProveedoresByFilters",
      deleteProveedor: "proveedores/deleteProveedor",
      changeStateProveedor: "proveedores/changeStateProveedor",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_PROVEEDOR:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.VER_PROVEEDOR:
            this.canSee = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_PROVEEDOR:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_PROVEEDOR:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .HABILITAR_DESHABILITAR_PROVEEDOR:
            this.canChangeState = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONFIGURACION_TABLAS_PROVEEDORES:
            this.canConfig = true;
            break;
          default:
            break;
        }
      });
    },
    async applyFilters() {
      this.applyBtnLoading = true;
      this.loadingTable = true;
      this.customizeFiltersApplied();
      const data = {
        codigo: this.codigo,
        cuit: this.cuit != null ? this.cuit.replaceAll("-", "") : null,
        nombre: this.nombre,
        nombreFantasia: this.nombreFantasia,
        incluirDeshabilitados: this.incluirDeshabilitados
      };
      try {
        const response = await this.getProveedoresByFilters(data);
        this.proveedores = response;
        this.showFilters = false;
      } catch {}
      this.applyBtnLoading = false;
      this.loadingTable = false;
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.codigo) {
        this.filtersApplied.splice(0, 1, {
          key: "codigo",
          label: "Código",
          model: this.codigo
        });
      }
      if (this.cuit) {
        this.filtersApplied.splice(1, 1, {
          key: "cuit",
          label: "CUIT",
          model: this.cuit
        });
      }
      if (this.nombre) {
        this.filtersApplied.splice(2, 1, {
          key: "nombre",
          label: "Nombre",
          model: this.nombre
        });
      }
      if (this.nombreFantasia) {
        this.filtersApplied.splice(3, 1, {
          key: "nombreFantasia",
          label: "Nombre de fantasía",
          model: this.nombreFantasia
        });
      }
      if (this.incluirDeshabilitados) {
        const includeDisabled = "SÍ";
        this.filtersApplied.splice(4, 1, {
          key: "incluirDeshabilitados",
          label: "Incluir proveedores deshabilitados",
          model: includeDisabled
        });
      } else {
        const includeDisabled = "NO";
        this.filtersApplied.splice(4, 1, {
          key: "incluirDeshabilitados",
          label: "Incluir proveedores deshabilitados",
          model: includeDisabled
        });
      }
    },
    async exportAllProvs() {
      this.exportAllBtnLoading = true;
      const data = {
        codigo: null,
        cuit: null,
        nombre: null,
        nombreFantasia: null,
        incluirDeshabilitados: true
      };
      try {
        let allProveedores = await this.getProveedoresByFilters(data);
        let result = [];
        allProveedores?.forEach(x =>
          result.push({
            ["Código"]: x.provCod,
            ["Nombre"]: x.provNom,
            ["Nombre fantasía"]: x.nombreFantasia,
            ["CUIT"]: x.cuit,
            ["Iva"]: x.iva != null ? x.iva.value : "",
            ["Calle"]: x.calle,
            ["Número"]: x.provNro,
            ["Barrio"]: x.barrio != null ? x.barrio.value : "",
            ["Localidad"]: x.localidad != null ? x.localidad.value : "",
            ["Código postal"]: x.codigoPostal,
            ["Teléfono"]: x.telefono,
            ["Concepto de Ganancias"]: x.concGan != null ? x.concGan.value : "",
            ["Condición de Ganancias"]:
              x.condGan != null ? x.condGan.value : "",
            ["Concepto de Ingresos Brutos"]:
              x.concIb != null ? x.concIb.value : "",
            ["Condición de Ingresos Brutos"]:
              x.condIb != null ? x.condIb.value : "",
            ["Tipo proveedor"]: x.tipoProv != null ? x.tipoProv.value : "",
            ["CBU"]: x.cbu,
            ["Tipo de cuenta"]:
              x.tipoCtaBancaria != null ? x.tipoCtaBancaria.value : "",
            ["N° de sucursal"]: x.sucursal,
            ["Número cuenta"]: x.nroCuenta,
            ["Observaciones"]: x.observaciones,
            ["Email"]: x.email,
            ["Moneda"]: x.moneda != null ? x.moneda.value : "",
            ["Habilitado"]: x.habilitado ? "SÍ" : "NO"
          })
        );
        let formato = {
          filas: result,
          hoja: "Proveedores"
        };
        helpersExcel.excelExport(formato, "Proveedores");
      } catch {}
      this.exportAllBtnLoading = false;
    },
    exportarConsulta() {
      let result = [];
      this.proveedores?.forEach(x =>
        result.push({
          ["Código"]: x.provCod,
          ["Nombre"]: x.provNom,
          ["Nombre fantasía"]: x.nombreFantasia,
          ["CUIT"]: x.cuit,
          ["Iva"]: x.iva != null ? x.iva.value : "",
          ["Calle"]: x.calle,
          ["Número"]: x.provNro,
          ["Barrio"]: x.barrio != null ? x.barrio.value : "",
          ["Localidad"]: x.localidad != null ? x.localidad.value : "",
          ["Código postal"]: x.codigoPostal,
          ["Teléfono"]: x.telefono,
          ["Concepto de Ganancias"]: x.concGan != null ? x.concGan.value : "",
          ["Condición de Ganancias"]: x.condGan != null ? x.condGan.value : "",
          ["Concepto de Ingresos Brutos"]:
            x.concIb != null ? x.concIb.value : "",
          ["Condición de Ingresos Brutos"]:
            x.condIb != null ? x.condIb.value : "",
          ["Tipo proveedor"]: x.tipoProv != null ? x.tipoProv.value : "",
          ["CBU"]: x.cbu,
          ["Tipo de cuenta"]:
            x.tipoCtaBancaria != null ? x.tipoCtaBancaria.value : "",
          ["N° de sucursal"]: x.sucursal,
          ["Número cuenta"]: x.nroCuenta,
          ["Observaciones"]: x.observaciones,
          ["Email"]: x.email,
          ["Moneda"]: x.moneda != null ? x.moneda.value : "",
          ["Habilitado"]: x.habilitado ? "SÍ" : "NO"
        })
      );
      let formato = {
        filas: result,
        hoja: "Proveedores"
      };
      helpersExcel.excelExport(formato, "Consulta de proveedores");
    },
    openChangeStateModal(item) {
      this.changeStateModal = true;
      this.proveedorToEdit = item;
    },
    async confirmChangeStateProveedor() {
      try {
        const response = await this.changeStateProveedor(
          this.proveedorToEdit.provId
        );
        if (response.status === 200) {
          this.changeStateModal = false;
          this.setAlert({
            type: "success",
            message: `Proveedor ${
              !this.proveedorToEdit.habilitado ? "habilitado" : "deshabilitado"
            } con éxito.`
          });
          this.applyFilters();
        }
      } catch {}
      this.changeStateModal = false;
    },
    openDelete(id) {
      this.idToDelete = id;
      this.openDeleteDialog = true;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.deleteProveedor(this.idToDelete);
        if (response.status === 200) {
          this.openDeleteDialog = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.applyFilters();
          this.loadingDeleteBtn = false;
        }
      } catch {}
      this.openDeleteDialog = false;
      this.loadingDeleteBtn = false;
    },
    openEditModal(item, editOrSee) {
      this.proveedorToEdit = item;
      this.editOrSee = editOrSee;
      this.modalEditProveedor = true;
    },
    closeAndReload(provCod) {
      this.modalEditProveedor = false;
      if (provCod != null) {
        this.nombre = this.nombreFantasia = this.cuit = null;
        this.codigo = provCod;
        this.showFilters = true;
        this.applyFilters();
      }
    },
    goToConfigTablasProveedores() {
      this.$router.push({
        name: "ConfiguracionTablasProveedores"
      });
    },
    validarCUIL(cuil) {
      if (cuil.length == 0) {
        return (this.isFormValid = true);
      }
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        this.setAlert({
          type: "warning",
          message: "El CUIL ingresado es incorrecto."
        });
        this.isFormValid = false;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    resetForm() {
      this.nombre = this.nombreFantasia = this.cuit = this.codigo = null;
    },
    closeOpenFilters() {
      this.showFilters = !this.showFilters;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style></style>
