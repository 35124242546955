var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-0 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('v-row',{staticClass:"mb-n3"},[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","md":"10"}},[_c('PageHeader',{attrs:{"title":_vm.title}})],1),(_vm.canConfig)?_c('v-col',{staticClass:"mt-9 pt-0",attrs:{"cols":"12","md":"2","align":"end"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"to-right",attrs:{"icon":""},on:{"click":function($event){return _vm.goToConfigTablasProveedores()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"30px"}},[_vm._v(_vm._s(_vm.settingsIcon))])],1)]}}],null,false,564186680)},[_c('span',[_vm._v("Acceder a la configuración de tablas de proveedores")])])],1):_vm._e()],1),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-2 p-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{staticClass:"py-1 px-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",attrs:{"disabled":_vm.filtersApplied.length == 0},on:{"click":function($event){$event.stopPropagation();return _vm.closeOpenFilters($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Código","hide-details":"auto","dense":"","clearable":"","rules":(_vm.cuit == null || _vm.cuit == '') &&
                      (_vm.nombre == null || _vm.nombre == '') &&
                      (_vm.nombreFantasia == null || _vm.nombreFantasia == '')
                        ? _vm.rules.required.concat([
                            _vm.rules.requiredTrim(_vm.codigo),
                            _vm.rules.maxLength(_vm.codigo, 10)
                          ])
                        : [],"outlined":""},model:{value:(_vm.codigo),callback:function ($$v) {_vm.codigo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"codigo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##-#######-#', '##-########-#']),expression:"['##-#######-#', '##-########-#']"}],attrs:{"type":"text","outlined":"","onkeypress":"return (event.charCode >= 48 && event.charCode <= 57)","dense":"","label":"CUIT","hide-details":"auto","autocomplete":"off","rules":(_vm.codigo == null || _vm.codigo == '') &&
                      (_vm.nombre == null || _vm.nombre == '') &&
                      (_vm.nombreFantasia == null || _vm.nombreFantasia == '')
                        ? _vm.rules.required.concat([_vm.rules.cuilValido(_vm.cuit)])
                        : []},model:{value:(_vm.cuit),callback:function ($$v) {_vm.cuit=$$v},expression:"cuit"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Nombre/s","hide-details":"auto","dense":"","rules":(_vm.codigo == null || _vm.codigo == '') &&
                      (_vm.cuit == null || _vm.cuit == '') &&
                      (_vm.nombreFantasia == null || _vm.nombreFantasia == '')
                        ? _vm.rules.required.concat([
                            _vm.rules.requiredTrim(_vm.nombre),
                            _vm.rules.maxLength(_vm.nombre, 50)
                          ])
                        : [],"clearable":"","outlined":""},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombre"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{attrs:{"label":"Nombre fantasía","hide-details":"auto","dense":"","rules":(_vm.codigo == null || _vm.codigo == '') &&
                      (_vm.cuit == null || _vm.cuit == '') &&
                      (_vm.nombre == null || _vm.nombre == '')
                        ? _vm.rules.required.concat([
                            _vm.rules.requiredTrim(_vm.nombreFantasia),
                            _vm.rules.maxLength(_vm.nombreFantasia, 50)
                          ])
                        : [],"clearable":"","outlined":""},model:{value:(_vm.nombreFantasia),callback:function ($$v) {_vm.nombreFantasia=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"nombreFantasia"}})],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"4","sm":"6","md":"4"}},[_c('v-subheader',{staticClass:"py-0 px-0"},[_c('v-switch',{staticClass:"ml-1",attrs:{"dense":"","outlined":""},model:{value:(_vm.incluirDeshabilitados),callback:function ($$v) {_vm.incluirDeshabilitados=$$v},expression:"incluirDeshabilitados"}}),_vm._v(" Incluir proveedores deshabilitados ")],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"12","md":"4","align":"center"}},[_c('v-subheader',[_vm._v("(*) Ingresar al menos un parámetro de búsqueda")])],1),_c('v-col',{staticClass:"text-center pt-2",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","small":"","loading":_vm.exportAllBtnLoading},on:{"click":function($event){return _vm.exportAllProvs()}}},[_vm._v(" Exportar todos los proveedores ")])],1),_c('v-col',{staticClass:"text-right pt-2",attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","small":"","loading":_vm.applyBtnLoading,"disabled":!_vm.isFormValid,"type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{ref:"proveedores-data-table",staticClass:"elevation-1",attrs:{"item-key":"provId","headers":_vm.headers,"items":_vm.proveedores,"loading":_vm.loadingTable,"search":_vm.search,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"6","md":"6","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openEditModal(null, 'edit')}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.habilitado",fn:function(ref){
                        var item = ref.item;
return [(item.habilitado)?_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"small":"","color":"red"}},[_vm._v(" "+_vm._s(_vm.crossIcon)+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
                        var item = ref.item;
                        var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? "mdi-account-check" : "mdi-account"))])]}}],null,true)},[_c('span',[_vm._v("Usuarios de carga y modificación")])])]}},{key:"expanded-item",fn:function(ref){
                        var headers = ref.headers;
                        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fecAlta)+" "),_c('strong',{staticStyle:{"padding-left":"80px"}},[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuAlta)+" "),_c('strong',{staticStyle:{"padding-left":"80px"}},[_vm._v("Fecha modificación:")]),_vm._v(" "+_vm._s(item.fecModi)+" "),_c('strong',{staticStyle:{"padding-left":"80px"}},[_vm._v("Usuario modificación:")]),_vm._v(" "+_vm._s(item.usuModi)+" ")])]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(_vm.canChangeState)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openChangeStateModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(!item.habilitado ? "mdi-checkbox-marked-circle-outline" : "mdi-checkbox-marked-circle-minus-outline")+" ")])]}}],null,true)},[(item.habilitado)?_c('span',[_vm._v("Deshabilitar proveedor")]):_c('span',[_vm._v("Habilitar proveedor")])]):_vm._e(),(_vm.canSee)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openEditModal(item, 'see')}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver proveedor")])]):_vm._e(),(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openEditModal(item, 'edit')}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar proveedor")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openDelete(item.provId)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar proveedor")])]):_vm._e()]}}],null,true)})],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","outlined":"","disabled":_vm.proveedores.length == 0},on:{"click":function($event){return _vm.exportarConsulta()}}},[_vm._v(" Exportar detalle ")])],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-6 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.modalEditProveedor)?_c('v-dialog',{attrs:{"max-width":"80%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReload(null)}},model:{value:(_vm.modalEditProveedor),callback:function ($$v) {_vm.modalEditProveedor=$$v},expression:"modalEditProveedor"}},[_c('EditProveedor',{attrs:{"proveedor":_vm.proveedorToEdit,"editOrSee":_vm.editOrSee},on:{"closeAndReload":function($event){return _vm.closeAndReload($event)}}})],1):_vm._e(),_c('ConfirmDialog',{attrs:{"text":_vm.proveedorToEdit != null && !_vm.proveedorToEdit.habilitado
        ? '¿Habilitar proveedor?'
        : '¿Deshabilitar proveedor?',"openConfirm":_vm.changeStateModal},on:{"update:openConfirm":[function($event){_vm.changeStateModal=$event},function($event){_vm.changeStateModal = false}],"update:open-confirm":function($event){_vm.changeStateModal=$event},"onConfirm":function($event){return _vm.confirmChangeStateProveedor()}}}),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"isLoading":_vm.loadingDeleteBtn,"openDelete":_vm.openDeleteDialog},on:{"update:openDelete":function($event){_vm.openDeleteDialog=$event},"update:open-delete":function($event){_vm.openDeleteDialog=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }