<template>
  <v-card>
    <v-card-title class="pb-0">
      <v-row>
        <v-col cols="8" class="py-0">
          <span class="primary--text" id="span-title">{{ formEditTitle }}</span>
        </v-col>
        <v-col cols="4" class="py-0 pr-0">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="to-right"
                icon
                @click="closeModal(null)"
                v-bind="attrs"
                v-on="on"
                id="btn-close-modal"
              >
                <v-icon tabindex="-1" id="icon-close-modal">{{
                  closeIcon
                }}</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <div v-if="!viewLoaded">
      <v-skeleton-loader type="article, actions"></v-skeleton-loader>
    </div>
    <div v-else>
      <v-card-text class="py-0">
        <v-card width="100%" outlined id="tabsCardEditProveedor" class="py-0">
          <v-card-text>
            <v-form
              v-model="isFormValid"
              ref="edit-proveedor-form"
              form="edit-proveedor-form"
              id="edit-proveedor-form"
              @submit.prevent="saveEdit()"
            >
              <v-tabs
                id="tabsEditProveedor"
                v-model="tab"
                active-class="active"
                center-active
                height="2rem"
                hide-slider
              >
                <v-tab href="#datos-generales" id="tab-datos-generales"
                  >Datos generales
                  <v-icon
                    small
                    class="primary--text"
                    id="icon-datos-generales"
                    v-if="tab != 'datos-generales'"
                    >{{ openTabIcon }}</v-icon
                  >
                </v-tab>
                <v-tab href="#datos-pagos" id="tab-datos-pagos"
                  >Datos de pagos
                  <v-icon
                    small
                    id="icon-datos-pagos"
                    class="primary--text"
                    v-if="tab != 'datos-pagos'"
                    >{{ openTabIcon }}</v-icon
                  >
                </v-tab>

                <v-tab href="#retenciones" id="tab-retenciones"
                  >Retenciones
                  <v-icon
                    small
                    class="primary--text"
                    id="icon-retenciones"
                    v-if="tab != 'retenciones'"
                    >{{ openTabIcon }}</v-icon
                  >
                </v-tab>
                <v-tab href="#jurisdicciones-iibb" id="tab-jurisdicciones-iibb"
                  >Jurisdicciones de IIBB
                  <v-icon
                    small
                    class="primary--text"
                    id="icon-jurisdicciones-iibb"
                    v-if="tab != 'jurisdicciones-iibb'"
                    >{{ openTabIcon }}</v-icon
                  >
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <!-- Datos generales -->
                <v-tab-item value="datos-generales">
                  <v-card flat>
                    <v-row dense class="pl-2">
                      <!-- Código -->
                      <v-col cols="2">
                        <v-text-field
                          :filled="editOrSee == 'see'"
                          id="text-field-codigo"
                          v-model.trim="codigo"
                          label="Código"
                          outlined
                          :disabled="
                            (generaCodigoAutomatico && proveedor == null) ||
                            editOrSee == 'see'
                          "
                          hide-details="auto"
                          :rules="
                            generaCodigoAutomatico && proveedor == null
                              ? []
                              : rules.required.concat([
                                  rules.noSpaces(codigo),
                                  rules.requiredTrim(codigo),
                                  rules.maxLength(codigo, 10),
                                ])
                          "
                          @keydown.tab="focusNextField('codigo')"
                          dense
                          required
                        ></v-text-field>
                      </v-col>

                      <!-- Habilitar código -->
                      <v-col
                        cols="1"
                        v-if="generaCodigoAutomatico && proveedor == null"
                        class="d-flex align-center justify-left"
                      >
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="green"
                              id="icon-generar-codigo-manual"
                              @click="
                                generaCodigoAutomatico = !generaCodigoAutomatico
                              "
                            >
                              mdi-checkbox-marked-circle-outline</v-icon
                            >
                          </template>
                          <span>Generar código manualmente</span>
                        </v-tooltip>
                      </v-col>

                      <!-- Nombre -->
                      <v-col cols="5">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-nombre"
                          v-model="nombre"
                          :rules="
                            rules.required.concat([
                              rules.requiredTrim(nombre),
                              rules.maxLength(nombre, 50),
                            ])
                          "
                          label="Nombre"
                          outlined
                          hide-details="auto"
                          dense
                          @keydown.tab="focusNextField('nombre')"
                          required
                          @input="nombre = nombre.replace(/\s{2,}/g, ' ')"
                        ></v-text-field>
                      </v-col>

                      <!-- Nombre de fantasía-->
                      <v-col
                        :cols="
                          generaCodigoAutomatico && proveedor == null
                            ? '4'
                            : '5'
                        "
                      >
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-nombre-fantasia"
                          v-model="nombreFantasia"
                          label="Nombre de fantasía"
                          outlined
                          clearable
                          :rules="
                            nombreFantasia == null
                              ? []
                              : [rules.maxLength(nombreFantasia, 100)]
                          "
                          @keydown.tab="focusNextField('nombreFantasia')"
                          hide-details="auto"
                          dense
                          required
                        ></v-text-field>
                      </v-col>

                      <!-- CUIT -->
                      <v-col cols="3">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-cuit"
                          v-model="cuit"
                          clearable
                          v-mask="['##-#######-#', '##-########-#']"
                          :rules="
                            cuit != null && cuit != ''
                              ? [rules.cuilValido(cuit)]
                              : []
                          "
                          @keydown.tab="focusNextField('cuit')"
                          label="CUIT"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Nº IIBB -->
                      <v-col cols="3">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-nroiibb"
                          v-model="nroIIBB"
                          label="Nº IIBB"
                          clearable
                          :rules="
                            nroIIBB == null
                              ? []
                              : [rules.maxLength(nroIIBB, 50)]
                          "
                          @keydown.tab="focusNextField('nroIibb')"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <!-- Persona Jurídica -->
                      <v-col cols="2">
                        <v-subheader class="py-0 px-0">
                          <v-switch
                            :disabled="editOrSee == 'see'"
                            id="switch-persona-juridica"
                            class="ml-1"
                            v-model="personaJuridica"
                            dense
                            outlined
                            @keydown.tab="focusNextField('personaJuridica')"
                          ></v-switch>
                          Persona jurídica
                        </v-subheader>
                      </v-col>

                      <!-- IVA -->
                      <v-col cols="4">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-iva"
                          v-model="ivaSelected"
                          :rules="rules.required"
                          :items="tiposIva"
                          item-text="value"
                          item-value="id"
                          @keydown.tab="focusNextField('tipoIva')"
                          label="IVA"
                          outlined
                          hide-details="auto"
                          dense
                        ></v-autocomplete>
                      </v-col>

                      <!-- Matrícula -->
                      <v-col cols="2">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-matricula"
                          v-model="matricula"
                          clearable
                          :rules="
                            matricula == null
                              ? []
                              : [rules.minMaxNumber(matricula, 0, 999999999)]
                          "
                          hide-details="auto"
                          label="Matrícula"
                          @keydown.tab="focusNextField('matricula')"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Mail -->
                      <v-col cols="4">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-mail"
                          v-model="mail"
                          clearable
                          :rules="
                            mail == null
                              ? []
                              : [rules.validateEmailFormat(mail)].concat([
                                  rules.maxLength(mail, 100),
                                ])
                          "
                          label="Email"
                          outlined
                          @keydown.tab="focusNextField('email')"
                          hide-details="auto"
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Calle -->
                      <v-col cols="4">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-calle"
                          v-model="calle"
                          clearable
                          @keydown.tab="focusNextField('calle')"
                          :rules="
                            calle == null ? [] : [rules.maxLength(calle, 50)]
                          "
                          label="Calle"
                          outlined
                          hide-details="auto"
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Nº -->
                      <v-col cols="2">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-numero"
                          v-model="numero"
                          clearable
                          @keydown.tab="focusNextField('numero')"
                          label="Nº"
                          :rules="
                            numero == null ? [] : [rules.maxLength(numero, 20)]
                          "
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Barrio -->
                      <v-col cols="4">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-barrio"
                          v-model="barrioSelected"
                          clearable
                          :items="barrios"
                          @keydown.tab="focusNextField('barrio')"
                          label="Barrio"
                          item-text="value"
                          item-value="id"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Localidad -->
                      <v-col cols="4">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-localidad"
                          v-model="localidadSelected"
                          clearable
                          @change="setCodPostalByLocalidad()"
                          :items="localidades"
                          label="Localidad"
                          @keydown.tab="focusNextField('localidad')"
                          outlined
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                          dense
                        ></v-autocomplete>
                      </v-col>

                      <!-- C. Postal -->
                      <v-col cols="2">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-codigo-postal"
                          v-model="codigoPostal"
                          clearable
                          @keydown.tab="focusNextField('cPostal')"
                          :rules="
                            codigoPostal == null
                              ? []
                              : [rules.maxLength(codigoPostal, 20)]
                          "
                          label="C. Postal"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Teléfono -->
                      <v-col cols="2">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-telefono"
                          clearable
                          v-model="telefono"
                          @keydown.tab="focusNextField('telefono')"
                          v-mask="'####################'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          :rules="
                            telefono == null
                              ? []
                              : [rules.maxLength(telefono, 20)]
                          "
                          label="Teléfono"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <!-- Vendedor -->
                      <v-col cols="6">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-vendedor"
                          v-model="vendedorSelected"
                          clearable
                          @keydown.tab="focusNextField('vendedor')"
                          :items="vendedores"
                          label="Vendedor"
                          item-text="value"
                          item-value="id"
                          outlined
                          hide-details="auto"
                          dense
                        ></v-autocomplete>
                      </v-col>

                      <!-- Moneda -->
                      <v-col cols="6">
                        <v-autocomplete
                          id="autocomplete-moneda"
                          v-model="monedaSelected"
                          :items="monedas"
                          :disabled="!monedaEditable || editOrSee == 'see'"
                          :filled="!monedaEditable || editOrSee == 'see'"
                          @keydown.tab="focusNextField('moneda')"
                          label="Moneda"
                          item-text="value"
                          item-value="id"
                          outlined
                          hide-details="auto"
                          dense
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <!-- Datos de pagos -->
                <v-tab-item value="datos-pagos">
                  <v-card flat>
                    <v-row dense>
                      <!-- Tipo de Proveedor -->
                      <v-col cols="5">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-tipo-proveedor"
                          v-model="tipoProveedorSelected"
                          :items="tiposProveedores"
                          label="Tipo proveedor"
                          clearable
                          outlined
                          @keydown.tab="focusNextField('tipoProv')"
                          dense
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Tipo de Operación -->
                      <v-col cols="5">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-tipo-operacion"
                          v-model="tipoOperacionSelected"
                          :items="tiposOperacion"
                          @keydown.tab="focusNextField('tipoOperacion')"
                          clearable
                          label="Tipo de operación"
                          outlined
                          dense
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Plazo de Pago -->
                      <v-col cols="2">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-plazo-pago"
                          v-model="plazoPago"
                          label="Plazo de pago"
                          @keydown.tab="focusNextField('plazoPago')"
                          clearable
                          outlined
                          dense
                          v-mask="'####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          :rules="
                            plazoPago == null
                              ? []
                              : [rules.minMaxNumber(plazoPago, 0, 9999)]
                          "
                          type="number"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <!-- CBU -->
                      <v-col cols="4">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-cbu"
                          v-model="cbu"
                          @keydown.tab="focusNextField('cbu')"
                          label="CBU"
                          clearable
                          v-mask="'######################'"
                          :rules="cbu == null ? [] : [rules.maxLength(cbu, 22)]"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <!-- Tipo de cuenta -->
                      <v-col cols="4">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-tipo-cuenta-bancaria"
                          v-model="tipoCuentaBancariaSelected"
                          :items="tiposCuentasBancarias"
                          label="Tipo de cuenta"
                          outlined
                          clearable
                          @keydown.tab="focusNextField('tipoCuenta')"
                          item-text="value"
                          item-value="id"
                          dense
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- N° de cuenta -->
                      <v-col cols="2">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-numero-cuenta"
                          v-model="numeroCuenta"
                          label="N° de cuenta"
                          outlined
                          @keydown.tab="focusNextField('nroCuenta')"
                          clearable
                          :rules="
                            numeroCuenta == null
                              ? []
                              : [rules.maxLength(numeroCuenta, 20)]
                          "
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <!-- Nº de Sucursal -->
                      <v-col cols="2">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-numero-sucursal"
                          v-model="numeroSucursal"
                          label="N° de sucursal"
                          outlined
                          clearable
                          @keydown.tab="focusNextField('nroSucursal')"
                          :rules="
                            numeroSucursal == null
                              ? []
                              : [rules.maxLength(numeroSucursal, 20)]
                          "
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <!-- A orden -->
                      <v-col cols="3">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-a-orden"
                          clearable
                          v-model="aOrden"
                          @keydown.tab="focusNextField('aOrden')"
                          :rules="
                            aOrden == null ? [] : [rules.maxLength(aOrden, 50)]
                          "
                          label="A orden"
                          outlined
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <!-- Descripción Mod. Pago -->
                      <v-col cols="4">
                        <v-text-field
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="text-field-descrip-mod-pago"
                          v-model="descripcionModPago"
                          label="Descrip. mod. pago"
                          clearable
                          @keydown.tab="focusNextField('descripModPago')"
                          outlined
                          :rules="
                            descripcionModPago == null
                              ? []
                              : [rules.maxLength(descripcionModPago, 100)]
                          "
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <!-- Modalidad de Pago -->
                      <v-col cols="5">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-modalidad-pago"
                          v-model="modalidadPagoSelected"
                          clearable
                          :items="modalidadesPago"
                          label="Tipo de valor"
                          outlined
                          item-text="value"
                          @keydown.tab="focusNextField('tipoValor')"
                          item-value="id"
                          dense
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Destino Pago -->
                      <v-col cols="4">
                        <v-textarea
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="textarea-destino-pago"
                          v-model="destinoPago"
                          label="Destino de pago"
                          clearable
                          @keydown.tab="focusNextField('destinoPago')"
                          :rules="
                            destinoPago == null
                              ? []
                              : [rules.maxLength(destinoPago, 100)]
                          "
                          outlined
                          dense
                          auto-grow
                          rows="1"
                          hide-details="auto"
                        ></v-textarea>
                      </v-col>

                      <!-- Observaciones -->
                      <v-col cols="8">
                        <v-textarea
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="textarea-observaciones"
                          v-model="observaciones"
                          clearable
                          @keydown.tab="focusNextField('observaciones')"
                          label="Observaciones"
                          outlined
                          :rules="
                            observaciones == null
                              ? []
                              : [rules.maxLength(observaciones, 500)]
                          "
                          auto-grow
                          rows="1"
                          dense
                          hide-details="auto"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" v-if="editOrSee == 'edit'">
                        <v-divider></v-divider>
                      </v-col>
                      <!-- Tipo de Imputación -->
                      <v-col cols="6" v-if="editOrSee == 'edit'">
                        <v-autocomplete
                          id="autocomplete-tipo-imputacion"
                          v-model="tipoImputacionSelected"
                          :items="tiposImputacion"
                          @keydown.tab="focusNextField('tipoImputacion')"
                          label="Tipo de imputación"
                          outlined
                          return-object
                          dense
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Plazo de Pago -->
                      <v-col cols="2" v-if="editOrSee == 'edit'">
                        <v-text-field
                          id="text-field-plazo-pago-dias"
                          v-model="plazoPagoDias"
                          v-mask="'####'"
                          onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                          label="Plazo de pago (Días)"
                          @keydown.tab="focusNextField('plazoPagoDias')"
                          outlined
                          :rules="
                            plazoPagoDias == null
                              ? []
                              : [rules.minMaxNumber(plazoPagoDias, 0, 9999)]
                          "
                          dense
                          type="number"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="2"
                        class="d-flex align-center justify-left"
                        v-if="editOrSee == 'edit'"
                      >
                        <v-btn
                          small
                          color="primary"
                          @click="agregarImputacion"
                          id="btn-agregar-imputacion"
                          @keydown.tab="focusNextField('addImputacionBtn')"
                        >
                          Agregar
                        </v-btn></v-col
                      >

                      <!-- Tabla imputaciones -->
                      <v-col cols="12">
                        <v-data-table
                          :headers="imputacionesHeaders"
                          :items="imputacionesItems"
                          class="elevation-1"
                          id="data-table-imputaciones"
                          dense
                          outlined
                          :hide-default-footer="imputacionesItems.length <= 10"
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                              small
                              @click="eliminarImputacion(item)"
                              id="icon-delete-imputacion"
                              tabindex="-1"
                              v-if="editOrSee == 'edit'"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row></v-card
                  >
                </v-tab-item>
                <!-- Retenciones -->
                <v-tab-item value="retenciones">
                  <v-card flat>
                    <v-row dense>
                      <!-- Condiciones de Ganancias -->
                      <v-col cols="6">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-condicion-ganancia"
                          v-model="condicionGananciasSelected"
                          :items="condicionesGanancias"
                          @keydown.tab="focusNextField('condGan')"
                          label="Condiciones de Ganancias"
                          clearable
                          outlined
                          dense
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Conceptos de Ganancia -->
                      <v-col cols="6">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-concepto-ganancia"
                          v-model="conceptoGananciaSelected"
                          :items="conceptosGanancias"
                          @keydown.tab="focusNextField('concGan')"
                          label="Conceptos de Ganancia"
                          outlined
                          clearable
                          dense
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Condiciones de Ing. Brutos -->
                      <v-col cols="6">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-condicion-iibb"
                          @keydown.tab="focusNextField('condIibb')"
                          v-model="condicionIngBrutosSelected"
                          :items="condicionesIngBrutos"
                          label="Condiciones de IIBB"
                          outlined
                          dense
                          clearable
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Conceptos de Ing. Brutos -->
                      <v-col cols="6">
                        <v-autocomplete
                          :disabled="editOrSee == 'see'"
                          :filled="editOrSee == 'see'"
                          id="autocomplete-concepto-iibb"
                          v-model="conceptoIngBrutosSelected"
                          :items="conceptosIngBrutos"
                          clearable
                          @keydown.tab="focusNextField('concIibb')"
                          label="Conceptos de IIBB"
                          outlined
                          dense
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>

                      <!-- Fecha 1º Retención -->
                      <v-col cols="4">
                        <v-menu
                          id="menu-fecha-primera-retencion"
                          ref="fecha-primera-retencion"
                          v-model="menuFechaPrimeraRetencion"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :disabled="editOrSee == 'see'"
                              :filled="editOrSee == 'see'"
                              id="text-field-fecha-primera-retencion"
                              v-model="fechaPrimeraRetencionTextField"
                              label="Fecha 1º retención de comercio e industria"
                              hint="Formato DD/MM/AAAA"
                              @blur="
                                fechaPrimeraRetencion = helpers.parseDateToIso(
                                  fechaPrimeraRetencionTextField
                                )
                              "
                              :rules="
                                fechaPrimeraRetencionTextField == null
                                  ? []
                                  : rules.validDate
                              "
                              outlined
                              autocomplete="not"
                              hide-details="auto"
                              @keydown.tab="focusNextField('fechaRet')"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fechaPrimeraRetencion"
                            id="date-picker-fecha-primera-retencion"
                            no-title
                            @change="
                              fechaPrimeraRetencionTextField =
                                helpers.formatDate(fechaPrimeraRetencion)
                            "
                            @input="menuFechaPrimeraRetencion = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- Otras Retenciones -->
                      <v-col cols="12" v-if="editOrSee == 'edit'">
                        <v-row align="center">
                          <v-col cols="6">
                            <v-autocomplete
                              id="autocomplete-otra-retencion"
                              v-model="otraRetencionSelected"
                              :items="otrasRetenciones"
                              label="Otras Retenciones"
                              return-object
                              outlined
                              dense
                              item-text="value"
                              @keydown.tab="focusNextField('otrasRetenciones')"
                              item-value="id"
                              hide-details="auto"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="2" class="d-flex justify-left">
                            <v-btn
                              id="btn-agregar-otra-retencion"
                              color="primary"
                              small
                              @click="agregarRetencion"
                              @keydown.tab="focusNextField('addRetencionBtn')"
                            >
                              Agregar
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>

                      <!-- Tabla de Otras Retenciones -->
                      <v-col cols="12">
                        <v-data-table
                          id="data-table-otras-retenciones"
                          :headers="otrasRetencionesHeaders"
                          :items="otrasRetencionesItems"
                          class="elevation-1"
                          dense
                          outlined
                          :hide-default-footer="
                            otrasRetencionesItems.length <= 10
                          "
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                              small
                              @click="eliminarRetencion(item)"
                              id="icon-delete-otra-retencion"
                              tabindex="-1"
                              v-if="editOrSee == 'edit'"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col cols="12" v-if="editOrSee == 'edit'">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <span
                          class="primary--text"
                          id="span-title-retenciones-alicuotas"
                          >Alícuotas de retenciones</span
                        >
                      </v-col>
                      <!-- Retención -->
                      <v-col cols="6" v-if="editOrSee == 'edit'">
                        <v-autocomplete
                          id="autocomplete-retencion-retenciones-alicuotas"
                          v-model="retencionAlicuotaSelected"
                          :items="retencionesAlicuotas"
                          label="Retención"
                          clearable
                          outlined
                          dense
                          @keydown.tab="focusNextField('retencionAlicuotas')"
                          return-object
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <!-- Tipo valor -->
                      <v-col cols="6" v-if="editOrSee == 'edit'">
                        <v-autocomplete
                          id="autocomplete-tipo-valor-retenciones-alicuotas"
                          v-model="tipoValorAlicuotaSelected"
                          :items="tiposValoresAlicuotasComputed"
                          label="Tipo valor"
                          return-object
                          clearable
                          @keydown.tab="focusNextField('tipoValorAlicuotas')"
                          outlined
                          dense
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <!-- Fecha desde -->
                      <v-col cols="2" v-if="editOrSee == 'edit'">
                        <v-menu
                          id="menu-fecha-desde-retenciones-alicuotas"
                          ref="fecha-desde-retenciones-alicuotas"
                          v-model="menuFechaDesdeRetencionesAlicuotas"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              id="text-field-fecha-desde-retenciones-alicuotas"
                              v-model="fechaDesdeRetencionAlicuotasSelected"
                              label="Fecha desde"
                              hint="Formato DD/MM/AAAA"
                              @blur="
                                fechaDesdeRetencionAlicuotas =
                                  helpers.parseDateToIso(
                                    fechaDesdeRetencionAlicuotasSelected
                                  )
                              "
                              @keydown.tab="
                                focusNextField('fechaDesdeAlicuotas')
                              "
                              :rules="
                                fechaDesdeRetencionAlicuotasSelected != null &&
                                fechaHastaRetencionAlicuotasSelected == null
                                  ? rules.validDate
                                  : fechaDesdeRetencionAlicuotasSelected !=
                                      null &&
                                    fechaHastaRetencionAlicuotasSelected != null
                                  ? rules.validDate.concat(
                                      new Date(
                                        parseDateToIso(
                                          fechaDesdeRetencionAlicuotasSelected
                                        )
                                      ) <=
                                        new Date(
                                          parseDateToIso(
                                            fechaHastaRetencionAlicuotasSelected
                                          )
                                        ) || 'Formato incorrecto'
                                    )
                                  : []
                              "
                              outlined
                              autocomplete="not"
                              hide-details="auto"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fechaDesdeRetencionAlicuotas"
                            id="date-picker-fecha-desde-retenciones-alicuotas"
                            no-title
                            @change="
                              fechaDesdeRetencionAlicuotasSelected =
                                helpers.formatDate(fechaDesdeRetencionAlicuotas)
                            "
                            @input="menuFechaDesdeRetencionesAlicuotas = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Fecha hasta -->
                      <v-col cols="2" v-if="editOrSee == 'edit'">
                        <v-menu
                          id="menu-fecha-hasta-retenciones-alicuotas"
                          ref="fecha-hasta-retenciones-alicuotas"
                          v-model="menuFechaHastaRetencionesAlicuotas"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              id="text-field-fecha-hasta-retenciones-alicuotas"
                              v-model="fechaHastaRetencionAlicuotasSelected"
                              label="Fecha hasta"
                              hint="Formato DD/MM/AAAA"
                              @keydown.tab="
                                focusNextField('fechaHastaAlicuotas')
                              "
                              @blur="
                                fechaHastaRetencionAlicuotas =
                                  helpers.parseDateToIso(
                                    fechaHastaRetencionAlicuotasSelected
                                  )
                              "
                              :rules="
                                fechaHastaRetencionAlicuotasSelected != null &&
                                fechaDesdeRetencionAlicuotasSelected == null
                                  ? rules.validDate
                                  : fechaHastaRetencionAlicuotasSelected !=
                                      null &&
                                    fechaDesdeRetencionAlicuotasSelected != null
                                  ? rules.validDate.concat(
                                      new Date(
                                        parseDateToIso(
                                          fechaDesdeRetencionAlicuotasSelected
                                        )
                                      ) <=
                                        new Date(
                                          parseDateToIso(
                                            fechaHastaRetencionAlicuotasSelected
                                          )
                                        ) || 'Formato incorrecto'
                                    )
                                  : []
                              "
                              outlined
                              autocomplete="not"
                              hide-details="auto"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fechaHastaRetencionAlicuotas"
                            id="date-picker-fecha-hasta-retenciones-alicuotas"
                            no-title
                            @change="
                              fechaHastaRetencionAlicuotasSelected =
                                helpers.formatDate(fechaHastaRetencionAlicuotas)
                            "
                            @input="menuFechaHastaRetencionesAlicuotas = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Alícuota -->
                      <v-col cols="2" v-if="editOrSee == 'edit'">
                        <currency-input
                          label="Alícuota"
                          v-model="alicuota"
                          dense
                          v-mask="'###'"
                          @keydown.tab="focusNextField('alicuota')"
                          class="right-input"
                          outlined
                          :rules="[]"
                          :options="currencyOptions"
                          :append-icon="porcentajeIcon"
                          :hideDetails="'auto'"
                        ></currency-input>
                      </v-col>
                      <!-- Grupo -->
                      <v-col cols="2" v-if="editOrSee == 'edit'">
                        <v-text-field
                          id="text-field-grupo-rentenciones-alicuotas"
                          v-model="grupoRetencionAlicuota"
                          label="Grupo"
                          outlined
                          @keydown.tab="focusNextField('grupo')"
                          :rules="
                            grupoRetencionAlicuota != null
                              ? [rules.maxLength(grupoRetencionAlicuota, 50)]
                              : []
                          "
                          dense
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col md="2" v-if="editOrSee == 'edit'">
                        <v-text-field
                          v-model.trim="numeroComprobante"
                          type="text"
                          label="N° comprobante"
                          dense
                          @keydown.tab="focusNextField('nroComprobante')"
                          outlined
                          :rules="
                            numeroComprobante != null
                              ? [rules.maxLength(numeroComprobante, 50)]
                              : []
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" class="pt-2" v-if="editOrSee == 'edit'">
                        <v-btn
                          color="primary"
                          small
                          @keydown.tab="focusNextField('addAlicuotaRetBtn')"
                          id="btn-agregar-alicuotas-retenciones"
                          @click="agregarAlicuotaRetencion"
                        >
                          Agregar
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-data-table
                          :headers="alicuotasRetencionesHeaders"
                          :items="alicuotasRetencionesItemsComputed"
                          id="data-table-alicuotas-retenciones"
                          class="elevation-1"
                          dense
                          outlined
                          :hide-default-footer="
                            alicuotasRetencionesItems.length <= 10
                          "
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                              small
                              id="icon-edit-alicuota-retencion"
                              v-if="editOrSee == 'edit'"
                              tabindex="-1"
                              @click="modificarAlicuotaRetencion(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              small
                              id="icon-delete-alicuota-retencion"
                              v-if="editOrSee == 'edit'"
                              tabindex="-1"
                              @click="eliminarAlicuotaRetencion(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col cols="12" v-if="editOrSee == 'edit'">
                        <v-divider></v-divider>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <span
                          class="primary--text"
                          id="span-title-retenciones-exenciones"
                          >Exenciones de retenciones</span
                        >
                      </v-col>
                      <!-- Retención -->
                      <v-col cols="4" v-if="editOrSee == 'edit'">
                        <v-autocomplete
                          id="autocomplete-retencion-exenciones-retenciones"
                          v-model="tipoValorRetencionSelected"
                          :items="tipoValoresRetenciones"
                          label="Retención"
                          outlined
                          @keydown.tab="focusNextField('retencionExenciones')"
                          dense
                          return-object
                          item-text="value"
                          item-value="id"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <!-- Fecha desde -->
                      <v-col cols="2" v-if="editOrSee == 'edit'">
                        <v-menu
                          id="menu-fecha-desde-exenciones-retenciones"
                          ref="fecha-desde-exenciones-retenciones"
                          v-model="menuFechaDesdeExencionesRetenciones"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              id="text-field-fecha-desde-exenciones-retenciones"
                              v-model="fechaDesdeExencionRetencionSelected"
                              label="Fecha desde"
                              hint="Formato DD/MM/AAAA"
                              @keydown.tab="
                                focusNextField('fechaDesdeExenciones')
                              "
                              @blur="
                                fechaDesdeExencionRetencion =
                                  helpers.parseDateToIso(
                                    fechaDesdeExencionRetencionSelected
                                  )
                              "
                              :rules="
                                fechaDesdeExencionRetencionSelected != null &&
                                fechaHastaExencionRetencionSelected == null
                                  ? rules.validDate
                                  : fechaDesdeExencionRetencionSelected !=
                                      null &&
                                    fechaHastaExencionRetencionSelected != null
                                  ? rules.validDate.concat(
                                      new Date(
                                        parseDateToIso(
                                          fechaDesdeExencionRetencionSelected
                                        )
                                      ) <=
                                        new Date(
                                          parseDateToIso(
                                            fechaHastaExencionRetencionSelected
                                          )
                                        ) || 'Formato incorrecto'
                                    )
                                  : []
                              "
                              outlined
                              autocomplete="not"
                              hide-details="auto"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fechaDesdeExencionRetencion"
                            id="date-picker-fecha-desde-exenciones-retenciones"
                            no-title
                            @change="
                              fechaDesdeExencionRetencionSelected =
                                helpers.formatDate(fechaDesdeExencionRetencion)
                            "
                            @input="menuFechaDesdeExencionesRetenciones = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Fecha hasta -->
                      <v-col cols="2" v-if="editOrSee == 'edit'">
                        <v-menu
                          id="menu-fecha-hasta-exenciones-retenciones"
                          ref="fecha-hasta-exenciones-retenciones"
                          v-model="menuFechaHastaExencionesRetenciones"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              id="text-field-fecha-hasta-exenciones-retenciones"
                              v-model="fechaHastaExencionRetencionSelected"
                              label="Fecha hasta"
                              hint="Formato DD/MM/AAAA"
                              @keydown.tab="
                                focusNextField('fechaHastaExenciones')
                              "
                              @blur="
                                fechaHastaExencionRetencion =
                                  helpers.parseDateToIso(
                                    fechaHastaExencionRetencionSelected
                                  )
                              "
                              :rules="
                                fechaHastaExencionRetencionSelected != null &&
                                fechaDesdeExencionRetencionSelected == null
                                  ? rules.validDate
                                  : fechaHastaExencionRetencionSelected !=
                                      null &&
                                    fechaDesdeExencionRetencionSelected != null
                                  ? rules.validDate.concat(
                                      new Date(
                                        parseDateToIso(
                                          fechaDesdeExencionRetencionSelected
                                        )
                                      ) <=
                                        new Date(
                                          parseDateToIso(
                                            fechaHastaExencionRetencionSelected
                                          )
                                        ) || 'Formato incorrecto'
                                    )
                                  : []
                              "
                              outlined
                              autocomplete="not"
                              hide-details="auto"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fechaHastaExencionRetencion"
                            id="date-picker-fecha-hasta-exenciones-retenciones"
                            no-title
                            @change="
                              fechaHastaExencionRetencionSelected =
                                helpers.formatDate(fechaHastaExencionRetencion)
                            "
                            @input="menuFechaHastaExencionesRetenciones = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col md="2" v-if="editOrSee == 'edit'">
                        <v-text-field
                          id="text-field-nro-certificado-exenciones-retenciones"
                          v-model.trim="numeroCertificado"
                          type="text"
                          label="N° certificado"
                          dense
                          @keydown.tab="focusNextField('nroCertificado')"
                          outlined
                          :rules="
                            numeroCertificado != null
                              ? [rules.maxLength(numeroCertificado, 50)]
                              : []
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" class="pt-2" v-if="editOrSee == 'edit'">
                        <v-btn
                          color="primary"
                          small
                          id="btn-agregar-exenciones-retenciones"
                          @keydown.tab="focusNextField('addExencionRetBtn')"
                          @click="agregarExencionRetencion"
                        >
                          Agregar
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-data-table
                          :headers="exencionesRetencionesHeaders"
                          :items="exencionesRetencionesItemsComputed"
                          id="data-table-exenciones-retenciones"
                          class="elevation-1"
                          dense
                          outlined
                          :hide-default-footer="
                            exencionesRetencionesItems.length <= 10
                          "
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                              small
                              id="icon-edit-exencion-retencion"
                              v-if="editOrSee == 'edit'"
                              tabindex="-1"
                              @click="modificarExencionRetencion(item)"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              small
                              id="icon-delete-exencion-retencion"
                              v-if="editOrSee == 'edit'"
                              tabindex="-1"
                              @click="eliminarExencionRetencion(item)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <!-- Jurisdicciones IIBB -->
                <v-tab-item value="jurisdicciones-iibb">
                  <v-card flat>
                    <v-row dense>
                      <v-col cols="4" v-if="editOrSee == 'edit'">
                        <v-select
                          id="select-jurisdicciones"
                          label="Jurisdicciones"
                          :items="jurisdicciones"
                          v-model="jurisdiccionSelected"
                          dense
                          outlined
                          @keydown.tab="focusNextField('jurisdicciones')"
                          hide-details="auto"
                          item-text="value"
                          item-value="id"
                          return-object
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="3" v-if="editOrSee == 'edit'">
                        <v-menu
                          id="menu-fecha-desde-jurisdiccion"
                          ref="fecha-desde-jurisdiccion"
                          v-model="menuFechaDesdeJurisdiccion"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              id="text-field-fecha-desde-jurisdiccion"
                              v-model="fechaDesdeJurisdiccionSelected"
                              label="Fecha desde"
                              hint="Formato DD/MM/AAAA"
                              @blur="
                                fechaDesdeJurisdiccion = helpers.parseDateToIso(
                                  fechaDesdeJurisdiccionSelected
                                )
                              "
                              @keydown.tab="focusNextField('fechaDesde')"
                              :rules="
                                fechaDesdeJurisdiccionSelected != null &&
                                fechaHastaJurisdiccionSelected == null
                                  ? rules.validDate
                                  : fechaDesdeJurisdiccionSelected != null &&
                                    fechaHastaJurisdiccionSelected != null
                                  ? rules.validDate.concat(
                                      new Date(
                                        parseDateToIso(
                                          fechaDesdeJurisdiccionSelected
                                        )
                                      ) <=
                                        new Date(
                                          parseDateToIso(
                                            fechaHastaJurisdiccionSelected
                                          )
                                        ) || 'Formato incorrecto'
                                    )
                                  : []
                              "
                              outlined
                              autocomplete="not"
                              hide-details="auto"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                              :append-icon="calendarIcon"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fechaDesdeJurisdiccion"
                            id="date-picker-fecha-desde-jurisdiccion"
                            no-title
                            @change="
                              fechaDesdeJurisdiccionSelected =
                                helpers.formatDate(fechaDesdeJurisdiccion)
                            "
                            @input="menuFechaDesdeJurisdiccion = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="3" v-if="editOrSee == 'edit'">
                        <v-menu
                          id="menu-fecha-hasta-jurisdiccion"
                          ref="fecha-hasta-jurisdiccion"
                          v-model="menuFechaHastaJurisdiccion"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              id="text-field-fecha-hasta-jurisdiccion"
                              v-model="fechaHastaJurisdiccionSelected"
                              label="Fecha hasta"
                              hint="Formato DD/MM/AAAA"
                              @keydown.tab="focusNextField('fechaHasta')"
                              @blur="
                                fechaHastaJurisdiccion = helpers.parseDateToIso(
                                  fechaHastaJurisdiccionSelected
                                )
                              "
                              :rules="
                                fechaHastaJurisdiccionSelected != null &&
                                fechaDesdeJurisdiccionSelected == null
                                  ? rules.validDate
                                  : fechaHastaJurisdiccionSelected != null &&
                                    fechaDesdeJurisdiccionSelected != null
                                  ? rules.validDate.concat(
                                      new Date(
                                        parseDateToIso(
                                          fechaDesdeJurisdiccionSelected
                                        )
                                      ) <=
                                        new Date(
                                          parseDateToIso(
                                            fechaHastaJurisdiccionSelected
                                          )
                                        ) || 'Formato incorrecto'
                                    )
                                  : []
                              "
                              outlined
                              autocomplete="not"
                              hide-details="auto"
                              dense
                              clearable
                              v-mask="'##/##/####'"
                              v-bind="attrs"
                              v-on="on"
                              :append-icon="calendarIcon"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            id="date-picker-fecha-hasta-jurisdiccion"
                            v-model="fechaHastaJurisdiccion"
                            no-title
                            @change="
                              fechaHastaJurisdiccionSelected =
                                helpers.formatDate(fechaHastaJurisdiccion)
                            "
                            @input="menuFechaHastaJurisdiccion = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="2"
                        class="d-flex align-center justify-left"
                        v-if="editOrSee == 'edit'"
                      >
                        <v-btn
                          color="primary"
                          small
                          id="btn-agregar-jurisdiccion"
                          @keydown.tab="focusNextField('addJurisdiccionBtn')"
                          @click="agregarJurisdiccion"
                        >
                          Agregar
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-data-table
                          :headers="jurisdiccionesHeaders"
                          :items="jurisdiccionesItems"
                          id="data-table-jurisdicciones"
                          class="elevation-1"
                          dense
                          outlined
                          :hide-default-footer="
                            jurisdiccionesItems.length <= 10
                          "
                        >
                          <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                              small
                              @click="modificarJurisdiccion(item)"
                              id="icon-edit-jurisdiccion"
                              v-if="editOrSee == 'edit'"
                              tabindex="-1"
                            >
                              mdi-pencil
                            </v-icon>
                            <v-icon
                              small
                              @click="eliminarJurisdiccion(item)"
                              id="icon-delete-jurisdiccion"
                              v-if="editOrSee == 'edit'"
                              tabindex="-1"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row></v-card
                  >
                </v-tab-item>
              </v-tabs-items></v-form
            >
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions class="pr-4" v-if="editOrSee == 'edit'">
        <v-spacer></v-spacer>
        <!-- <v-btn outlined @click="closeModal(false)"> Cancelar </v-btn> -->
        <v-btn
          type="submit"
          id="btn-guardar-proveedor"
          :disabled="!isFormValid"
          :loading="saveBtnLoading"
          form="edit-proveedor-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import helpers from "@/utils/helpers.js";
import validateCUIL from "@/utils/helpers/validateCUIL";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "EditProveedor",
  components: {
    CurrencyInput,
  },
  directives: { mask },

  props: {
    proveedor: {
      type: Object,
      required: false,
    },
    editOrSee: {
      type: String,
      required: false,
      default: "see",
    },
  },
  data() {
    return {
      formEditTitle: "Editar proveedor",
      helpers: helpers,
      tab: "datos-generales",
      porcentajeIcon: enums.icons.PERCENT_OUTLINE,
      fieldOrder: {
        "datos-generales": [
          "start",
          "codigo",
          "nombre",
          "nombreFantasia",
          "cuit",
          "nroIibb",
          "personaJuridica",
          "tipoIva",
          "matricula",
          "email",
          "calle",
          "numero",
          "barrio",
          "localidad",
          "cPostal",
          "telefono",
          "vendedor",
          "moneda",
        ],
        "datos-pagos": [
          "tipoProv",
          "tipoOperacion",
          "plazoPago",
          "cbu",
          "tipoCuenta",
          "nroCuenta",
          "nroSucursal",
          "aOrden",
          "descripModPago",
          "tipoValor",
          "destinoPago",
          "observaciones",
          "tipoImputacion",
          "plazoPagoDias",
          "addImputacionBtn",
        ],
        retenciones: [
          "condGan",
          "concGan",
          "condIibb",
          "concIibb",
          "fechaRet",
          "otrasRetenciones",
          "addRetencionBtn",
          "retencionAlicuotas",
          "tipoValorAlicuotas",
          "fechaDesdeAlicuotas",
          "fechaHastaAlicuotas",
          "alicuota",
          "grupo",
          "nroComprobante",
          "addAlicuotaRetBtn",
          "retencionExenciones",
          "fechaDesdeExenciones",
          "fechaHastaExenciones",
          "nroCertificado",
          "addExencionRetBtn",
        ],
        "jurisdicciones-iibb": [
          "jurisdicciones",
          "fechaDesde",
          "fechaHasta",
          "addJurisdiccionBtn",
        ],
      },
      rules: rules,
      openTabIcon: enums.icons.DOWN_ARROW,
      closeIcon: enums.icons.CLOSE,
      isFormValid: false,
      saveBtnLoading: false,
      codigo: null,
      nombre: null,
      nombreFantasia: null,
      cuit: null,
      nroIIBB: null,
      personaJuridica: false,
      ivaSelected: null,
      tiposIva: [],
      matricula: null,
      mail: null,
      calle: null,
      numero: null,
      barrioSelected: null,
      barrios: [],
      localidades: [],
      localidadSelected: null,
      codigoPostal: null,
      telefono: null,
      vendedorSelected: null,
      vendedores: [],
      monedaSelected: null,
      monedas: [],
      generaCodigoAutomatico: false,
      tipoProveedorSelected: null,
      tiposProveedores: [],
      tipoOperacionSelected: null,
      tiposOperacion: [],
      plazoPago: null,
      cbu: "",
      numeroCuenta: "",
      numeroSucursal: "",
      aOrden: "",
      descripcionModPago: "",
      modalidadPagoSelected: null,
      modalidadesPago: [],
      destinoPago: "",
      observaciones: "",
      tipoImputacionSelected: null,
      tiposImputacion: [],
      plazoPagoDias: 0,
      imputacionesHeaders: [
        {
          text: "Tipo de imputación",
          value: "tipoImputacion.value",
          sortable: false,
        },
        {
          text: "Plazo de pago",
          value: "plazoPagoDias",
          sortable: false,
          align: "end",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      imputacionesItems: [],
      condicionGananciasSelected: null,
      condicionesGanancias: [],
      conceptoGananciaSelected: null,
      conceptosGanancias: [],
      condicionIngBrutosSelected: null,
      condicionesIngBrutos: [],
      conceptoIngBrutosSelected: null,
      conceptosIngBrutos: [],
      calendarIcon: enums.icons.CALENDAR,
      fechaPrimeraRetencion: null,
      menuFechaPrimeraRetencion: false,
      fechaPrimeraRetencionTextField: null,
      otraRetencionSelected: null,
      otrasRetenciones: [],
      otrasRetencionesHeaders: [
        {
          text: "Otras retenciones",
          value: "tipoValor.value",
          sortable: false,
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      otrasRetencionesItems: [],
      jurisdiccionesHeaders: [
        { text: "Jurisdicción", value: "jurisdiccion.value", sortable: false },
        { text: "Fecha desde", value: "fechaDesde", sortable: false },
        { text: "Fecha hasta", value: "fechaHasta", sortable: false },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      jurisdiccionesItems: [],
      jurisdicciones: [],
      jurisdiccionSelected: null,
      menuFechaDesdeJurisdiccion: false,
      fechaDesdeJurisdiccion: null,
      fechaDesdeJurisdiccionSelected: null,
      menuFechaHastaJurisdiccion: false,
      fechaHastaJurisdiccion: null,
      fechaHastaJurisdiccionSelected: null,
      jurisdiccionId: null,
      tiposCuentasBancarias: [],
      tipoCuentaBancariaSelected: null,
      monedaEditable: true,
      viewLoaded: false,
      retencionAlicuotaId: null,
      retencionesAlicuotas: [
        { id: "IB", value: "Ingresos brutos" },
        { id: "IVA", value: "IVA" },
        { id: "M", value: "Municipal" },
      ],
      retencionAlicuotaSelected: null,
      tiposValoresAlicuotas: [],
      tipoValorAlicuotaSelected: null,
      menuFechaDesdeRetencionesAlicuotas: false,
      fechaDesdeRetencionAlicuotas: null,
      fechaDesdeRetencionAlicuotasSelected: null,
      menuFechaHastaRetencionesAlicuotas: false,
      fechaHastaRetencionAlicuotas: null,
      fechaHastaRetencionAlicuotasSelected: null,
      alicuota: 0.0,
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "hidden",
        valueRange: {
          min: 0,
          max: 200,
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true,
      },
      grupoRetencionAlicuota: null,
      numeroComprobante: null,
      alicuotasRetencionesHeaders: [
        { text: "Retención", value: "impuesto.value", sortable: false },
        { text: "Tipo valor", value: "tipoValor.value", sortable: false },
        { text: "Fecha desde", value: "fechaDesde", sortable: false },
        { text: "Fecha hasta", value: "fechaHasta", sortable: false },
        { text: "Alícuota", value: "alicuota", sortable: false, align: "end" },
        { text: "Grupo", value: "grupo", sortable: false },
        { text: "N° comprobante", value: "nroComprobante", sortable: false },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      alicuotasRetencionesItems: [],
      tipoValoresRetenciones: [],
      tipoValorRetencionSelected: null,
      exencionesRetencionesHeaders: [
        {
          text: "Retención",
          value: "tipoValor.value",
          sortable: false,
        },
        { text: "Fecha desde", value: "fechaDesde", sortable: false },
        { text: "Fecha hasta", value: "fechaHasta", sortable: false },
        { text: "N° certificado", value: "nroCertificado", sortable: false },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      exencionesRetencionesItems: [],
      menuFechaDesdeExencionesRetenciones: false,
      fechaDesdeExencionRetencion: null,
      fechaDesdeExencionRetencionSelected: null,
      menuFechaHastaExencionesRetenciones: false,
      fechaHastaExencionRetencion: null,
      fechaHastaExencionRetencionSelected: null,
      numeroCertificado: null,
      exencionRetencionId: null,
    };
  },
  computed: {
    tiposValoresAlicuotasComputed() {
      return this.retencionAlicuotaSelected
        ? this.tiposValoresAlicuotas.filter(
            (x) => x.description === this.retencionAlicuotaSelected.id
          )
        : [];
    },
    alicuotasRetencionesItemsComputed() {
      return this.alicuotasRetencionesItems.length > 0
        ? this.alicuotasRetencionesItems.sort(
            (a, b) =>
              new Date(helpers.parseDateToIso(b.fechaHasta)) -
              new Date(helpers.parseDateToIso(a.fechaHasta))
          )
        : [];
    },
    exencionesRetencionesItemsComputed() {
      return this.exencionesRetencionesItems.length > 0
        ? this.exencionesRetencionesItems.sort(
            (a, b) =>
              new Date(helpers.parseDateToIso(b.fechaHasta)) -
              new Date(helpers.parseDateToIso(a.fechaHasta))
          )
        : [];
    },
  },
  created() {
    this.setSelects();
    this.getParametrosGrales();
  },
  mounted() {
    if (this.proveedor != null) {
      this.setProveedor();
      if (this.editOrSee == "see") this.formEditTitle = "Consulta de proveedor";
    } else this.formEditTitle = "Nuevo proveedor";
  },
  methods: {
    ...mapActions({
      getLocalidades: "afiliaciones/getLocalidades",
      getBarrios: "afiliaciones/getBarrios",
      getTiposIvaCobradores: "configuracion/getTiposIvaCobradores",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getVendedores: "afiliaciones/getVendedores",
      getParametrosGeneralesProveedores:
        "proveedores/getParametrosGeneralesProveedores",
      fetchTiposProveedores: "proveedores/fetchTiposProveedores",
      getTipoOperacion: "taxonomy/getTipoOperacion",
      getModalidadesPagoProveedores:
        "afiliaciones/getModalidadesPagoProveedores",
      getTiposImputacion: "taxonomy/getTiposImputacion",
      getCondicionesIIBB: "afiliaciones/getCondicionesIIBB",
      getConceptosIIBB: "afiliaciones/getConceptosIIBB",
      getCondicionesGanancias: "taxonomy/getCondicionesGanancias",
      getConceptosGanancias: "taxonomy/getConceptosGanancias",
      getRetencionesOtroImp: "taxonomy/getRetencionesOtroImp",
      getJurisdiccionesIIBBEntidadesFacturantes:
        "taxonomy/getJurisdiccionesIIBBEntidadesFacturantes",
      getTipoCuentasBancarias: "taxonomy/getTipoCuentasBancarias",
      getTiposValoresRetIvaIbMun: "taxonomy/getTiposValoresRetIvaIbMun",
      getTipoValoresRetenciones: "taxonomy/getTipoValoresRetenciones",
      saveProveedor: "proveedores/saveProveedor",
      setAlert: "user/setAlert",
    }),
    focusNextField(currentField) {
      const fields = this.fieldOrder[this.tab];
      const currentIndex = fields.indexOf(currentField);

      if (currentIndex !== -1 && currentIndex < fields.length - 1) {
        const nextField = fields[currentIndex + 1];
        this.$refs[nextField]?.focus();
      } else {
        const tabs = Object.keys(this.fieldOrder);
        const currentTabIndex = tabs.indexOf(this.tab);

        if (currentTabIndex !== -1 && currentTabIndex < tabs.length - 1) {
          const nextTab = tabs[currentTabIndex + 1];
          this.tab = nextTab;

          const nextField = this.fieldOrder[nextTab][0];
          this.$nextTick(() => {
            this.$refs[nextField]?.focus();
          });
        }
      }
    },
    async setSelects() {
      try {
        this.barrios = await this.getBarrios();
        this.localidades = await this.getLocalidades();
        this.tiposIva = await this.getTiposIvaCobradores();
        this.monedas = await this.getMonedasTaxonomy("habCarga");
        if (this.proveedor == null)
          this.monedaSelected = this.monedas.find((x) => x.bool).id;
        this.vendedores = await this.getVendedores();
        this.tiposProveedores = await this.fetchTiposProveedores();
        this.tiposOperacion = await this.getTipoOperacion();
        this.modalidadesPago = await this.getModalidadesPagoProveedores();
        this.tiposImputacion = await this.getTiposImputacion();
        this.conceptosGanancias = await this.getConceptosGanancias();
        this.condicionesGanancias = await this.getCondicionesGanancias();
        this.conceptosIngBrutos = await this.getConceptosIIBB();
        this.condicionesIngBrutos = await this.getCondicionesIIBB();
        this.otrasRetenciones = await this.getRetencionesOtroImp();
        this.jurisdicciones =
          await this.getJurisdiccionesIIBBEntidadesFacturantes();
        this.tiposCuentasBancarias = await this.getTipoCuentasBancarias();
        this.tipoValoresRetenciones = await this.getTipoValoresRetenciones();
        this.tiposValoresAlicuotas = await this.getTiposValoresRetIvaIbMun();
      } catch {
        this.setAlert({
          type: "warning",
          message: "No ha sido posible cargar todos los datos correctamente.",
        });
      }
      this.viewLoaded = true;
      if (this.editOrSee != "see") {
        this.$nextTick(() => {
          const element = document.getElementById("text-field-codigo");
          if (element) element.focus();
        });
      }
    },
    async setTiposValoresAlicuotas() {
      try {
        this.tiposValoresAlicuotas = await this.getTiposValoresByTipoRetencion(
          this.retencionAlicuotaSelected.id
        );
      } catch {}
    },
    async getParametrosGrales() {
      const response = await this.getParametrosGeneralesProveedores();
      this.generaCodigoAutomatico = response.generaCodProv;
    },
    agregarImputacion() {
      if (this.tipoImputacionSelected != null) {
        const exists = this.imputacionesItems.find(
          (item) => item.tipoImputacion.id == this.tipoImputacionSelected.id
        );
        if (!exists) {
          this.imputacionesItems.push({
            provComptiId: null,
            tipoImputacion: this.tipoImputacionSelected,
            plazoPagoDias: this.plazoPagoDias,
          });
          this.tipoImputacionSelected = null;
          this.plazoPagoDias = 0;
        } else
          this.setAlert({
            type: "warning",
            message: "El tipo de imputación contable ya ha sido ingresada.",
          });
      } else {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar un tipo de imputación.",
        });
      }
      this.$nextTick(() => {
        const element = document.getElementById("autocomplete-tipo-imputacion");
        if (element) element.focus();
      });
    },
    eliminarImputacion(item) {
      const index = this.imputacionesItems.indexOf(item);
      if (index > -1) {
        this.imputacionesItems.splice(index, 1);
      }
    },
    agregarRetencion() {
      if (this.otraRetencionSelected) {
        const exists = this.otrasRetencionesItems.find(
          (item) => item.tipoValor.id == this.otraRetencionSelected.id
        );
        if (!exists) {
          this.otrasRetencionesItems.push({
            provRetId: null,
            tipoValor: this.otraRetencionSelected,
          });
          this.otraRetencionSelected = null;
        } else
          this.setAlert({
            type: "warning",
            message: "La retención seleccionada ya ha sido ingresada.",
          });
      } else {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar una retención.",
        });
      }
      this.$nextTick(() => {
        const element = document.getElementById("autocomplete-otra-retencion");
        if (element) element.focus();
      });
    },
    eliminarRetencion(item) {
      const index = this.otrasRetencionesItems.indexOf(item);
      if (index > -1) {
        this.otrasRetencionesItems.splice(index, 1);
      }
    },
    agregarAlicuotaRetencion() {
      if (
        this.retencionAlicuotaSelected &&
        this.tipoValorAlicuotaSelected &&
        this.alicuota != null &&
        this.alicuota >= 0.0 &&
        this.fechaDesdeRetencionAlicuotas != null &&
        this.fechaHastaRetencionAlicuotas != null &&
        new Date(
          helpers.parseDateToIso(this.fechaDesdeRetencionAlicuotasSelected)
        ) <=
          new Date(
            helpers.parseDateToIso(this.fechaHastaRetencionAlicuotasSelected)
          )
      ) {
        const nuevaFechaDesde = new Date(this.fechaDesdeRetencionAlicuotas);
        const nuevaFechaHasta = new Date(this.fechaHastaRetencionAlicuotas);

        const vigenciasSuperpuestas = this.alicuotasRetencionesItems.some(
          (item) => {
            return (
              item.tipoValor.id === this.tipoValorAlicuotaSelected.id &&
              item.impuesto.id === this.retencionAlicuotaSelected.id &&
              ((nuevaFechaDesde <=
                new Date(helpers.parseDateToIso(item.fechaHasta)) &&
                nuevaFechaDesde >=
                  new Date(helpers.parseDateToIso(item.fechaDesde))) ||
                (nuevaFechaHasta <=
                  new Date(helpers.parseDateToIso(item.fechaHasta)) &&
                  nuevaFechaHasta >=
                    new Date(helpers.parseDateToIso(item.fechaDesde))) ||
                (nuevaFechaDesde <=
                  new Date(helpers.parseDateToIso(item.fechaDesde)) &&
                  nuevaFechaHasta >=
                    new Date(helpers.parseDateToIso(item.fechaHasta))))
            );
          }
        );

        if (vigenciasSuperpuestas) {
          this.setAlert({
            type: "warning",
            message: `Las fechas ingresadas se superponen con una vigencia existente.`,
          });
          return;
        }

        const exists = this.alicuotasRetencionesItems.find(
          (item) =>
            item.impuesto.id == this.retencionAlicuotaSelected.id &&
            item.tipoValor.id == this.tipoValorAlicuotaSelected.id &&
            item.fechaDesde == this.fechaDesdeRetencionAlicuotasSelected &&
            item.fechaHasta == this.fechaHastaRetencionAlicuotasSelected
        );
        if (!exists) {
          this.alicuotasRetencionesItems.push({
            proArId: this.retencionAlicuotaId ?? null,
            impuesto: this.retencionAlicuotaSelected,
            tipoValor: this.tipoValorAlicuotaSelected,
            fechaDesde: this.fechaDesdeRetencionAlicuotasSelected,
            fechaHasta: this.fechaHastaRetencionAlicuotasSelected,
            alicuota: this.alicuota,
            grupo: this.grupoRetencionAlicuota ?? "",
            nroComprobante: this.numeroComprobante ?? "",
          });
          this.retencionAlicuotaSelected =
            this.tipoValorAlicuotaSelected =
            this.fechaDesdeRetencionAlicuotasSelected =
            this.fechaDesdeRetencionAlicuotas =
            this.fechaHastaRetencionAlicuotasSelected =
            this.fechaHastaRetencionAlicuotas =
            this.grupoRetencionAlicuota =
            this.numeroComprobante =
              null;
          this.alicuota = 0.0;
        } else {
          this.setAlert({
            type: "warning",
            message: "El registro ya ha sido ingresado.",
          });
        }
      } else {
        this.setAlert({
          type: "warning",
          message:
            "No es posible agregar el registro debido a que existe al menos un error en los datos ingresados.",
        });
      }
      this.$nextTick(() => {
        const element = document.getElementById(
          "autocomplete-retencion-retenciones-alicuotas"
        );
        if (element) element.focus();
      });
    },
    modificarAlicuotaRetencion(item) {
      this.alicuotasRetencionesItems = this.alicuotasRetencionesItems.filter(
        (x) => x != item
      );
      this.retencionAlicuotaId = item.proArId;
      this.retencionAlicuotaSelected = item.impuesto;
      this.tipoValorAlicuotaSelected = item.tipoValor;
      this.fechaDesdeRetencionAlicuotasSelected = item.fechaDesde;
      this.fechaDesdeRetencionAlicuotas = helpers.parseDateToIso(
        item.fechaDesde
      );
      this.fechaHastaRetencionAlicuotasSelected = item.fechaHasta;
      this.fechaHastaRetencionAlicuotas = helpers.parseDateToIso(
        item.fechaHasta
      );
      this.alicuota = item.alicuota;
      this.grupoRetencionAlicuota = item.grupo;
      this.numeroComprobante = item.nroComprobante;
    },
    eliminarAlicuotaRetencion(item) {
      const index = this.alicuotasRetencionesItems.indexOf(item);
      if (index > -1) {
        this.alicuotasRetencionesItems.splice(index, 1);
      }
    },
    agregarExencionRetencion() {
      if (
        this.tipoValorRetencionSelected &&
        this.fechaDesdeExencionRetencion != null &&
        this.fechaHastaExencionRetencion != null &&
        new Date(
          helpers.parseDateToIso(this.fechaDesdeExencionRetencionSelected)
        ) <=
          new Date(
            helpers.parseDateToIso(this.fechaHastaExencionRetencionSelected)
          )
      ) {
        const nuevaFechaDesde = new Date(this.fechaDesdeExencionRetencion);
        const nuevaFechaHasta = new Date(this.fechaHastaExencionRetencion);

        const vigenciasSuperpuestas = this.exencionesRetencionesItems.some(
          (item) => {
            return (
              item.tipoValor.id === this.tipoValorRetencionSelected.id &&
              ((nuevaFechaDesde <=
                new Date(helpers.parseDateToIso(item.fechaHasta)) &&
                nuevaFechaDesde >=
                  new Date(helpers.parseDateToIso(item.fechaDesde))) ||
                (nuevaFechaHasta <=
                  new Date(helpers.parseDateToIso(item.fechaHasta)) &&
                  nuevaFechaHasta >=
                    new Date(helpers.parseDateToIso(item.fechaDesde))) ||
                (nuevaFechaDesde <=
                  new Date(helpers.parseDateToIso(item.fechaDesde)) &&
                  nuevaFechaHasta >=
                    new Date(helpers.parseDateToIso(item.fechaHasta))))
            );
          }
        );

        if (vigenciasSuperpuestas) {
          this.setAlert({
            type: "warning",
            message: `Las fechas ingresadas se superponen con una vigencia existente.`,
          });
          return;
        }

        const exists = this.exencionesRetencionesItems.find(
          (item) =>
            item.tipoValor.id == this.tipoValorRetencionSelected.id &&
            item.fechaDesde == this.fechaDesdeExencionRetencionSelected &&
            item.fechaHasta == this.fechaHastaExencionRetencionSelected
        );
        if (!exists) {
          this.exencionesRetencionesItems.push({
            proArExeId: this.exencionRetencionId ?? null,
            tipoValor: this.tipoValorRetencionSelected,
            fechaDesde: this.fechaDesdeExencionRetencionSelected,
            fechaHasta: this.fechaHastaExencionRetencionSelected,
            nroCertificado: this.numeroCertificado ?? "",
          });
          this.tipoValorRetencionSelected =
            this.exencionRetencionId =
            this.fechaDesdeExencionRetencionSelected =
            this.fechaDesdeExencionRetencion =
            this.fechaHastaExencionRetencionSelected =
            this.fechaHastaExencionRetencion =
            this.numeroCertificado =
              null;
        } else {
          this.setAlert({
            type: "warning",
            message: "El registro ya ha sido ingresado.",
          });
        }
      } else {
        this.setAlert({
          type: "warning",
          message:
            "No es posible agregar el registro debido a que existe al menos un error en los datos ingresados.",
        });
      }
      this.$nextTick(() => {
        const element = document.getElementById(
          "autocomplete-retencion-exenciones-retenciones"
        );
        if (element) element.focus();
      });
    },

    modificarExencionRetencion(item) {
      this.exencionesRetencionesItems = this.exencionesRetencionesItems.filter(
        (x) => x != item
      );
      this.exencionRetencionId = item.proArExeId;
      this.tipoValorRetencionSelected = item.tipoValor;
      this.fechaDesdeExencionRetencionSelected = item.fechaDesde;
      this.fechaDesdeExencionRetencion = helpers.parseDateToIso(
        item.fechaDesde
      );
      this.fechaHastaExencionRetencionSelected = item.fechaHasta;
      this.fechaHastaExencionRetencion = helpers.parseDateToIso(
        item.fechaHasta
      );
      this.numeroCertificado = item.nroCertificado;
    },
    eliminarExencionRetencion(item) {
      const index = this.exencionesRetencionesItems.indexOf(item);
      if (index > -1) {
        this.exencionesRetencionesItems.splice(index, 1);
      }
    },
    agregarJurisdiccion() {
      if (
        this.jurisdiccionSelected &&
        this.fechaDesdeJurisdiccion != null &&
        this.fechaHastaJurisdiccion != null &&
        new Date(helpers.parseDateToIso(this.fechaDesdeJurisdiccionSelected)) <=
          new Date(helpers.parseDateToIso(this.fechaHastaJurisdiccionSelected))
      ) {
        const nuevaFechaDesde = new Date(this.fechaDesdeJurisdiccion);
        const nuevaFechaHasta = new Date(this.fechaHastaJurisdiccion);

        const vigenciasSuperpuestas = this.jurisdiccionesItems.some((item) => {
          return (
            item.jurisdiccion.id === this.jurisdiccionSelected.id &&
            ((nuevaFechaDesde <=
              new Date(helpers.parseDateToIso(item.fechaHasta)) &&
              nuevaFechaDesde >=
                new Date(helpers.parseDateToIso(item.fechaDesde))) ||
              (nuevaFechaHasta <=
                new Date(helpers.parseDateToIso(item.fechaHasta)) &&
                nuevaFechaHasta >=
                  new Date(helpers.parseDateToIso(item.fechaDesde))) ||
              (nuevaFechaDesde <=
                new Date(helpers.parseDateToIso(item.fechaDesde)) &&
                nuevaFechaHasta >=
                  new Date(helpers.parseDateToIso(item.fechaHasta))))
          );
        });

        if (vigenciasSuperpuestas) {
          this.setAlert({
            type: "warning",
            message: `Las fechas ingresadas de la jurisdicción '${this.jurisdiccionSelected.value}' se superponen con una vigencia existente.`,
          });
          return;
        }

        const exists = this.jurisdiccionesItems.find(
          (item) =>
            item.jurisdiccion.id == this.jurisdiccionSelected.id &&
            item.fechaDesde == this.fechaDesdeJurisdiccionSelected &&
            item.fechaHasta == this.fechaHastaJurisdiccionSelected
        );
        if (!exists) {
          this.jurisdiccionesItems.push({
            provJurIbId: this.jurisdiccionId ?? null,
            jurisdiccion: this.jurisdiccionSelected,
            fechaDesde: this.fechaDesdeJurisdiccionSelected,
            fechaHasta: this.fechaHastaJurisdiccionSelected,
          });
          this.jurisdiccionSelected =
            this.jurisdiccionId =
            this.fechaDesdeJurisdiccionSelected =
            this.fechaDesdeJurisdiccion =
            this.fechaHastaJurisdiccionSelected =
            this.fechaHastaJurisdiccion =
              null;
        } else {
          this.setAlert({
            type: "warning",
            message: "La jurisdicción seleccionada ya ha sido ingresada.",
          });
        }
      } else {
        this.setAlert({
          type: "warning",
          message:
            "No es posible agregar el registro debido a que existe al menos un error en los datos ingresados.",
        });
      }
      this.$nextTick(() => {
        const element = document.getElementById("select-jurisdicciones");
        if (element) element.focus();
      });
    },

    modificarJurisdiccion(item) {
      this.jurisdiccionesItems = this.jurisdiccionesItems.filter(
        (x) => x != item
      );
      this.jurisdiccionId = item.provJurIbId;
      this.jurisdiccionSelected = item.jurisdiccion;
      this.fechaDesdeJurisdiccionSelected = item.fechaDesde;
      this.fechaDesdeJurisdiccion = helpers.parseDateToIso(item.fechaDesde);
      this.fechaHastaJurisdiccionSelected = item.fechaHasta;
      this.fechaHastaJurisdiccion = helpers.parseDateToIso(item.fechaHasta);
    },
    eliminarJurisdiccion(item) {
      const index = this.jurisdiccionesItems.indexOf(item);
      if (index > -1) {
        this.jurisdiccionesItems.splice(index, 1);
      }
    },
    setCodPostalByLocalidad() {
      if (this.localidadSelected != null)
        this.codigoPostal = this.localidades.find(
          (x) => x.id == this.localidadSelected
        ).description;
      else this.codigoPostal = null;
    },
    validarCUIL(cuit) {
      if (cuit == null || cuit == "") return [];
      const res = validateCUIL.validateCUITCUIL(cuit);
      if (res === false) {
        return ["El CUIL ingresado es incorrecto."];
      }
    },
    setProveedor() {
      try {
        this.codigo = this.proveedor.provCod;
        this.nombre = this.proveedor.provNom;
        this.cuit = this.proveedor.cuit;
        this.ivaSelected = this.proveedor.iva?.id;
        this.calle = this.proveedor.calle;
        this.numero = this.proveedor.provNro;
        this.barrioSelected = this.proveedor.barrio?.id;
        this.localidadSelected = this.proveedor.localidad?.id;
        this.codigoPostal = this.proveedor.codigoPostal;
        this.conceptoGananciaSelected = this.proveedor.concGan?.id;
        this.condicionGananciasSelected = this.proveedor.condGan?.id;
        this.conceptoIngBrutosSelected = this.proveedor.concIb?.id;
        this.condicionIngBrutosSelected = this.proveedor.condIb?.id;
        this.vendedorSelected = this.proveedor.venAfi?.id;
        this.tipoProveedorSelected = this.proveedor.tipoProv?.id;
        this.tipoOperacionSelected = this.proveedor.tipoOperacion?.id;
        this.tipoCuentaBancariaSelected = this.proveedor.tipoCtaBancaria?.id;
        this.aOrden = this.proveedor.provAOrden;
        this.personaJuridica = this.proveedor.esPersonaJuridica;
        this.fechaPrimeraRetencionTextField = this.proveedor.fechaPrimerRet;
        this.fechaPrimeraRetencion = helpers.parseDateToIso(
          this.proveedor.fechaPrimerRet
        );
        this.numeroCuenta = this.proveedor.nroCuenta;
        this.matricula = this.proveedor.matricula;
        this.plazoPago = this.proveedor.plazoPago;
        this.modalidadPagoSelected = this.proveedor.tvModalidadPago?.id;
        this.descripcionModPago = this.proveedor.modalidadPago;
        this.destinoPago = this.proveedor.destinoPago;
        this.nombreFantasia = this.proveedor.nombreFantasia;
        this.cbu = this.proveedor.cbu;
        this.numeroSucursal = this.proveedor.sucursal;
        this.telefono = this.proveedor.telefono;
        this.nroIIBB = this.proveedor.ingrBrutosNro;
        this.observaciones = this.proveedor.observaciones;
        this.mail = this.proveedor.email;
        this.monedaSelected = this.proveedor.moneda?.id;
        this.monedaEditable = this.proveedor.monedaEditable;
        this.otrasRetencionesItems = [...this.proveedor.retencionesOtroImp];
        this.jurisdiccionesItems = [...this.proveedor.jurisdiccionesIibb];
        this.imputacionesItems = [...this.proveedor.plazos];
        this.alicuotasRetencionesItems =
          this.proveedor.alicuotasRetenciones.map((item) => ({
            ...item,
            impuesto: this.retencionesAlicuotas.find(
              (x) => x.id == item.impuesto
            ),
          }));
        this.exencionesRetencionesItems = [
          ...this.proveedor.exencionesRetenciones,
        ];
      } catch {}
    },
    async saveEdit() {
      this.saveBtnLoading = true;
      const data = {
        ProvId: this.proveedor?.provId || null,
        ProvCod: this.codigo?.trim() || null,
        ProvNom: this.nombre?.trim() || null,
        Cuit: this.cuit?.trim().replaceAll("-", "") || null,
        Iva: this.tiposIva.find((x) => x.id === this.ivaSelected) || null,
        Calle: this.calle?.trim() || null,
        ProvNro: this.numero?.trim() || null,
        IngrBrutosNro: this.nroIIBB?.trim() || null,
        Barrio: this.barrios.find((x) => x.id === this.barrioSelected) || null,
        Localidad:
          this.localidades.find((x) => x.id === this.localidadSelected) || null,
        CodigoPostal: this.codigoPostal?.trim() || null,
        Telefono: this.telefono?.trim() || null,
        ConcGan:
          this.conceptosGanancias.find(
            (x) => x.id === this.conceptoGananciaSelected
          ) || null,
        CondGan:
          this.condicionesGanancias.find(
            (x) => x.id === this.condicionGananciasSelected
          ) || null,
        ConcIb:
          this.conceptosIngBrutos.find(
            (x) => x.id === this.conceptoIngBrutosSelected
          ) || null,
        CondIb:
          this.condicionesIngBrutos.find(
            (x) => x.id === this.condicionIngBrutosSelected
          ) || null,
        VenAfi:
          this.vendedores.find((x) => x.id === this.vendedorSelected) || null,
        TipoProv:
          this.tiposProveedores.find(
            (x) => x.id === this.tipoProveedorSelected
          ) || null,
        ProvAOrden: this.aOrden?.trim() || null,
        PlazoPago: this.plazoPago || null,
        EsPersonaJuridica: this.personaJuridica || false,
        TvModalidadPago:
          this.modalidadesPago.find(
            (x) => x.id === this.modalidadPagoSelected
          ) || null,
        Matricula: this.matricula || null,
        FechaPrimerRet: this.fechaPrimeraRetencionTextField || null,
        ModalidadPago: this.descripcionModPago?.trim() || null,
        DestinoPago: this.destinoPago?.trim() || null,
        NombreFantasia: this.nombreFantasia?.trim() || null,
        CBU: this.cbu?.trim() || null,
        Sucursal: this.numeroSucursal?.trim() || null,
        NroCuenta: this.numeroCuenta?.trim() || null,
        Observaciones: this.observaciones?.trim() || null,
        TipoCtaBancaria:
          this.tiposCuentasBancarias.find(
            (x) => x.id === this.tipoCuentaBancariaSelected
          ) || null,
        Email: this.mail?.trim() || null,
        Moneda: this.monedas.find((x) => x.id === this.monedaSelected) || null,
        TipoOperacion:
          this.tiposOperacion.find(
            (x) => x.id === this.tipoOperacionSelected
          ) || null,
        Plazos: this.imputacionesItems.map((item) => ({
          ProvComptiId: item.tipoImputacion?.provComptiId || 0,
          TipoImputacion:
            this.tiposImputacion.find(
              (x) => x.id === item.tipoImputacion?.id
            ) || null,
          PlazoPagoDias: item.plazoPagoDias,
        })),
        JurisdiccionesIibb: this.jurisdiccionesItems.map((item) => ({
          ProvJurIbId: item.provJurIbId || 0,
          Jurisdiccion:
            this.jurisdicciones.find((x) => x.id === item.jurisdiccion?.id) ||
            null,
          FechaDesde: item.fechaDesde || null,
          FechaHasta: item.fechaHasta || null,
        })),
        RetencionesOtroImp: this.otrasRetencionesItems.map((item) => ({
          ProvRetId: item.provRetId || 0,
          TipoValor:
            this.otrasRetenciones.find((x) => x.id === item.tipoValor?.id) ||
            null,
        })),
        AlicuotasRetenciones: this.alicuotasRetencionesItems.map((item) => ({
          ProArId: item.proArId || 0,
          Impuesto: item.impuesto?.id,
          Alicuota: item.alicuota,
          Grupo: item.grupo,
          NroComprobante: item.nroComprobante,
          TipoValor:
            this.tiposValoresAlicuotas.find(
              (x) => x.id === item.tipoValor?.id
            ) || null,
          FechaDesde: item.fechaDesde || null,
          FechaHasta: item.fechaHasta || null,
        })),
        ExencionesRetenciones: this.exencionesRetencionesItems.map((item) => ({
          ProArExeId: item.proArExeId || 0,
          NroCertificado: item.nroCertificado,
          TipoValor:
            this.tipoValoresRetenciones.find(
              (x) => x.id === item.tipoValor?.id
            ) || null,
          FechaDesde: item.fechaDesde || null,
          FechaHasta: item.fechaHasta || null,
        })),
      };
      try {
        const response = await this.saveProveedor(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal(response.data.data);
        }
      } catch {}
      this.saveBtnLoading = false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModal(provCod) {
      this.$emit("closeAndReload", provCod);
    },
  },
};
</script>

<style scoped>
.active {
  color: #f8f9fa !important;
  background-color: #195472 !important;
}
#tabsCardEditProveedor .active {
  border-radius: 0.2rem !important;
}
.scroll {
  overflow-y: scroll;
}
.tabsEditProveedor {
  border-radius: 0.2rem !important;
}
.right-input >>> input {
  text-align: right;
}
</style>
